import { useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { GrAttachment } from "react-icons/gr";
import { IoMdArrowDropdown } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
import { useCategory_productQuery } from "../../../utils/api/categorie/categorie.api";
import ConfigApi from "../../../utils/api/config/config.api";
import { getRoutePath } from "../../routes/routes";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import { HtmlInput } from "../admin/Produits/AjoutProduitAdmin";
import { useFieldHandler } from "../LaunchACampaingn/LaunchACampain";
// import DropZoneImage from "./DropZoneImage";
import "../LaunchACampaingn/LaunchACampain.css";
import UseAjouterProduitForm from "../merchant/ProductList/UseAjouterProduitForm";

const AjoutOffre = () => {
  const user = useAppSelector((s) => s.user.user);
  const navigate = useNavigate();

  const { register, onSubmit, errors, setValue, isLoading, isSuccess } =
    UseAjouterProduitForm();
  const [description, setDescription] = useFieldHandler(
    register,
    setValue,
    "",
    "description"
  );
  return (
    <div className="launch-campaign-component">
      <FrontHeader />
      <section className="launch-campaing pb-5  custom-container margin-container">
        <div className="launch-camapaign-form-container custom-container py-5 px-2 px-sm-4">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
              <h1 className="launch-title">
                <button onClick={() => navigate(-1)} className="back-btn">
                  <FiArrowLeft
                    style={{
                      color: "#021849",
                    }}
                  />
                </button>
                Ajouter une offre
              </h1>
              {!user && (
                <div className="mt-4">
                  <h4 className="my-3">
                    Veuillez vous connecter pour ajouter une offre et devenir
                    commerçant
                  </h4>
                  <NavLink to={getRoutePath("login")} className="btn-submit">
                    Se connecter
                  </NavLink>
                </div>
              )}
              {!!user && (
                <OffreForm
                  {...{
                    onSubmit,
                    errors,
                    register,
                    isLoading,
                    setValue,
                    description,
                    setDescription,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <FrontFooter />
    </div>
  );
};

export default AjoutOffre;

export function OffreForm({
  onSubmit,
  errors,
  register,
  isLoading,
  setValue,
  description,
  setDescription,
  initFile = null,
  update = false,
}) {
  const { data: config } = ConfigApi.useGetConfigQuery();
  const iconStyle = {
    fontSize: "18",
    color: "#5a5a5a",
    opacity: 0.3,
  };
  const { data: categorie = [] } = useCategory_productQuery();

  const [preview, setPreview] = useState<any>(initFile);

  const handleChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0] as File;
    setPreview(URL.createObjectURL(file));
    register("avatar");
    setValue("avatar", file);
  };
  return (
    <form className="mt-4" onSubmit={onSubmit}>
      <div className="form-group mb-4">
        <label htmlFor="product-name" className="majjal-label mb-2">
          Nom du produit
        </label>
        <input
          type="text"
          className="form-control-majjal form-control form-control-lg"
          id="product-name"
          placeholder="Nom du produit"
          {...register("name")}
        />
        {errors.name && (
          <div className="alert alert-danger sia-alert-danger closer mt-2">
            {errors.name?.message}
          </div>
        )}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="desc-product" className="majjal-label mb-2">
          Description produit
        </label>
        <HtmlInput
          data={description}
          name="description"
          onChange={setDescription}
        />
        {errors.description && (
          <div className="alert alert-danger sia-alert-danger closer mt-2">
            {errors.description?.message}
          </div>
        )}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="category" className="majjal-label mb-2">
          Catégorie
        </label>
        <div className="position-relative">
          <select
            className="form-control-majjal form-select-majjal"
            {...register("category")}
          >
            <option>Choisir une catégorie</option>
            {categorie?.map((category) => (
              <option value={category?.id} key={category?.id}>
                {category?.name}
              </option>
            ))}
          </select>
          <IoMdArrowDropdown
            style={{
              fontSize: "32px",
              color: "#8E8EA1",
              position: "absolute",
              right: 10,
              top: "22%",
            }}
          />
        </div>
      </div>

      <div className="col-md-12 form-group">
        <label htmlFor="photo-identite-recto" className=" flex-sb">
          Image produit
        </label>
        <label
          htmlFor="photo-identite-recto"
          className="auth-identite-file flex-sb"
        >
          <span>Photo</span>
          <GrAttachment className="auth-file-input-icon" style={iconStyle} />
        </label>
        <input
          type="file"
          className="op-0 h-0 imgInp"
          placeholder="Image produit"
          id="photo-identite-recto"
          onChange={handleChange}
          accept="image/*"
          name="recto_cni"
        />
        {!!preview && (
          <div className="img-preview-container">
            <img src={preview} className="cni-img-preview" />
          </div>
        )}
        {errors.avatar && (
          <div className="alert alert-danger sia-alert-danger closer mt-2">
            {errors.avatar?.message}
          </div>
        )}
      </div>

      <div className="form-group mb-4">
        <label htmlFor="price" className="majjal-label mb-2">
          Prix
        </label>
        <input
          type="number"
          className="form-control-majjal form-control form-control-lg"
          id="price"
          placeholder="Prix en FCFA"
          {...register("prix")}
        />
        {errors.prix && (
          <div className="alert alert-danger sia-alert-danger closer mt-2">
            {errors.prix?.message}
          </div>
        )}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="discount" className="majjal-label mb-2">
          Discount
        </label>
        <input
          type="number"
          className="form-control-majjal form-control form-control-lg"
          placeholder="Discount exemple 10%"
          {...register("discount")}
        />
        {errors.discount && (
          <div className="alert alert-danger sia-alert-danger closer mt-2">
            {errors.discount?.message}
          </div>
        )}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="point" className="majjal-label mb-2">
          Point ( {config?.point}F = 1 point )
        </label>
        <input
          type="number"
          className="form-control-majjal form-control form-control-lg bg-slate"
          id="point"
          placeholder="500"
          {...register("point")}
        />
        {errors.point && (
          <div className="alert alert-danger sia-alert-danger closer mt-2">
            {errors.point?.message}
          </div>
        )}
      </div>

      <div className="submit-btn-container">
        {isLoading && (
          <button className="btn-submit" type="button" disabled>
            Chargement...
          </button>
        )}
        {!isLoading && (
          <button className="btn-submit" type="submit">
            {update ? "Modifier" : "Ajouter"}
          </button>
        )}
      </div>
    </form>
  );
}
