import moment from "moment";
import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { onLogout } from "../../../redux/slices/user.slice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import NotificationApi from "../../../utils/api/notification/notification.api";
import { Notification } from "../../../utils/api/notification/notification.type";
import Logo from "../../assets/appImage/Logo.png";
import Buy from "../../assets/icons/Buy.png";
import Notif from "../../assets/icons/Notif.png";
import { isCommercant, isInvestisseur } from "../../routes/renderRoutes";
import { getRoutePath, Routes } from "../../routes/routes";
import VersionChecker from "../../utils/VersionChecker";
import "./FrontHeader.css";

const FrontHeader = () => {
  const { user } = useAppSelector((s) => s.user);
  const isConnected = !!user?.id;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const is_investisseur = isInvestisseur(user);
  const listeProduit = useAppSelector((p) => p.produit);
  return (
    <div className="front-header-component">
      <div className="fixed-top">
        <div className="">
          <nav className="navbar navbar-expand-lg navbar-light headering">
            <div className="header-app-identify-container">
              <div className="app-logo-and-version-container">
                <Link
                  className="navbar-brand ml-logo-desktop navbar-brand-costum"
                  to="/"
                >
                  <img src={Logo} alt="Maajjal" />
                </Link>
                <div className="version-viewer-container app-side">
                  {"v" + VersionChecker()}
                </div>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div
              className="navbar-collapse collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav desktop-menu-listing ms-auto me-auto">
                <li className="menu-item">
                  <NavLink to="/" className="menu-link">
                    Home
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink to="/app/campagnes" className="menu-link">
                    Campagnes
                  </NavLink>
                </li>
                {/* {!isConnected ? null : ( */}
                <li className="menu-item">
                  <NavLink to="/app/lancer-une-campagne" className="menu-link">
                    Lancer une campagne
                  </NavLink>
                </li>
                {/* )} */}

                <li className="menu-item">
                  <NavLink to="/app/marketplace" className="menu-link">
                    Marketplace
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink to="/app/qui-sommes-nous" className="menu-link">
                    Qui-sommes-nous ?
                  </NavLink>
                </li>
              </ul>
              <div className="d-flex flex-center-mobile">
                <div className="bloc-icon-menu">
                  <div className="me-3 position-relative">
                    <NavLink to="/app/mon-panier" className="btn text-theme">
                      {listeProduit?.length > 0 && (
                        <span className="custom-position-absolute translate-middle badge">
                          {listeProduit?.length}
                        </span>
                      )}
                      <img src={Buy} alt="Icon Buy" className="icon-img" />
                    </NavLink>
                  </div>
                  <Notifications />
                  {!isConnected && (
                    <div>
                      <NavLink to="/se-connecter" className="btn text-theme">
                        Connexion
                      </NavLink>
                    </div>
                  )}
                  <div className="dropdown">
                    {!isConnected ? null : (
                      <button
                        className="btn dropdown-toggle cnt-dropdown-toggle"
                        type="button"
                        onClick={(e) => {
                          if (!isConnected) {
                            e.preventDefault();
                            navigate(getRoutePath("login"));
                          }
                        }}
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="dropdown-btn-logged-user-name">
                          {user?.first_name + " " + user?.last_name}
                        </span>
                      </button>
                    )}

                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {isConnected && (
                        <>
                          <DropDownItem
                            title="Tableau de bord"
                            route={
                              is_investisseur
                                ? "ListeProjets"
                                : isCommercant(user)
                                ? "MerchantDashboard"
                                : "DashboardAdmin"
                            }
                          />
                        </>
                      )}
                      {isConnected && (
                        <div className="text-center">
                          <button
                            onClick={() => {
                              dispatch(onLogout());
                              navigate(getRoutePath("home"));
                            }}
                            className="btn btn-logout-desktop pt-2"
                          >
                            <span className="item-nav-menu">Déconnexion</span>
                          </button>
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
export default FrontHeader;

type MenuLinkItemProps = {
  route: Routes;
  img?: any;
  title: string;
  arr?: any;
};

export function DropDownItem({ route, title, arr }: MenuLinkItemProps) {
  return (
    <li>
      <NavLink
        className="dropdown-item dropdown-item-custom drop-header"
        to={getRoutePath(route)}
      >
        {title}{" "}
        {title === "Notifications" && (
          <span className="badge badge-infos notif-badge"> {arr?.length}</span>
        )}
        {title === "Messages" && (
          <span className="badge badge-infos notif-badge">
            {" "}
            {/* {numberMessages(arr)}{" "} */}
          </span>
        )}
      </NavLink>
    </li>
  );
}

function Notifications() {
  const [data, count, onShow, onClick] = useNotifications();

  return (
    <div className="me-3 dropdown position-relative" id="dropdown-header">
      <button
        className="btn text-theme dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        onClick={() => onShow()}
        aria-expanded="false"
      >
        <img src={Notif} alt="Icon Buy" className="icon-img" />
        {count > 0 && (
          <span className="custom-position-absolute translate-middle badge">
            {count}
          </span>
        )}
      </button>
      <ul
        style={{ width: 250 }}
        className="dropdown-menu"
        aria-labelledby="dropdownMenuButton1"
      >
        {data.length === 0 && (
          <li className="dropdown-item cursor-pointer">Pas de notification</li>
        )}
        {data.map((item) => (
          <li
            style={{ whiteSpace: "initial", fontSize: 12 }}
            className="dropdown-item cursor-pointer"
            onClick={() => onClick(item)}
            key={item.id}
          >
            {item.text}
          </li>
        ))}
      </ul>
    </div>
  );
}

export function useNotifications(): [
  Notification[],
  number,
  () => any,
  (item: Notification) => any
] {
  const navigate = useNavigate();
  const [update] = NotificationApi.useUpdateMutation();
  const [count, setCount] = useState(0);
  const { data = [] } = NotificationApi.useGetQuery();
  useEffect(() => {
    setCount(data.filter((f) => !f.read).length);
  }, [data]);
  const user = useAppSelector((s) => s.user.user);

  const onClick = (item: Notification) => {
    if (user?.user_type !== "admin") {
      if (item.achat) {
        navigate(
          getRoutePath(user?.is_double_type ? "CommandeList" : "MesCommandes")
        );
      }
    } else {
      if (item.achat) {
        navigate(getRoutePath("CommandesAdmin"));
      }
    }
  };
  const onShow = () => {
    for (let i of data) {
      if (!i.read) {
        update({ read: true, id: i.id });
      }
    }
  };
  const monthAfter = moment().add(-1, "months").toDate();
  return [
    data.filter((p) => !p.read || moment(p.date_created).toDate() > monthAfter),
    count,
    onShow,
    onClick,
  ];
}
