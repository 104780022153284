import { useEffect, useState } from "react";
import { GrAttachment } from "react-icons/gr";
import { Avis } from "../../../../utils/api/avis/avis.type";
import { getAvatar, useModalActions } from "../../../../utils/utils";
import BtnCloseModal from "../../../shared/BtnCloseModal";
import useAvisForm from "./form";
const iconStyle = {
  fontSize: "18",
  color: "#5a5a5a",
  opacity: 0.3,
};
const AvisForm = ({ modalId, item }: { modalId: string; item?: Avis }) => {
  const ma = useModalActions(modalId);
  const { register, formState, onSubmit, setValue, isLoading } = useAvisForm(
    ma.hide,
    item?.slug
  );
  const [preview, setPreview] = useState(item ? getAvatar(item.avatar) : null);
  const handleChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    register("avatar");
    setValue("avatar", e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };
  useEffect(() => {
    if (item) {
      register("fullname");
      register("fonction");
      register("avis");
      setValue("fullname", item.fullname);
      setValue("fonction", item.fonction);
      setValue("avis", item.avis);
    }
  }, [item]);
  return (
    <div className="modal fade" id={modalId}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content px-4 py-3">
          <div className="flex-csb mb-4">
            <h1 className="admin-modal-title m-0">
              {item ? "Modifier un avis" : "Ajouter un avis"}
            </h1>
            <BtnCloseModal />
          </div>
          <div className="reponse-form-container">
            <form onSubmit={onSubmit}>
              <div className="form-group mb-4">
                <label htmlFor="fullname" className="majjal-label mb-2">
                  Prénom & Nom
                </label>
                <input
                  type="text"
                  className="form-control-majjal form-control form-control-lg"
                  placeholder="Prénom & Nom"
                  {...register("fullname")}
                />
                {formState.errors.fullname && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {formState.errors.fullname.message}
                  </div>
                )}
              </div>
              <div className="form-group mb-4">
                <label htmlFor="fonction" className="majjal-label mb-2">
                  Fonction
                </label>
                <input
                  type="text"
                  className="form-control-majjal form-control form-control-lg"
                  placeholder="Fonction"
                  {...register("fonction")}
                />
                {formState.errors.fonction && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {formState.errors.fonction.message}
                  </div>
                )}
              </div>
              <div className="col-md-12 form-input-col">
                {/* <DropZoneImage setValue={setValue} /> */}
                <label htmlFor="avatar" className="majjal-label mb-2">
                  Image
                </label>
                <label htmlFor="avatar" className="auth-identite-file flex-sb">
                  <span className="img-produit-placeholder">
                    Image (PNG, JPEG, GIF)
                  </span>
                  <GrAttachment
                    className="auth-file-input-icon"
                    style={iconStyle}
                  />
                </label>
                <input
                  type="file"
                  className="op-0 h-0 imgInp"
                  placeholder="Photo d’identité recto"
                  id="avatar"
                  onChange={handleChange}
                  accept="image/*"
                />
                {!!preview && (
                  <div className="img-preview-container">
                    <img src={preview} className="cni-img-preview" />
                  </div>
                )}
                {formState.errors.avatar && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {formState.errors.avatar.message}
                  </div>
                )}
              </div>
              <div className="form-group mb-4">
                <label htmlFor="avis" className="majjal-label mb-2">
                  Avis
                </label>
                <textarea
                  className="form-control-majjal form-control form-control-lg"
                  placeholder="Avis"
                  {...register("avis")}
                />
                {formState.errors.avis && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {formState.errors.avis.message}
                  </div>
                )}
              </div>
              <div className="col-md-12 submit-input-col">
                <div className="submit-btn-container">
                  {isLoading && (
                    <button disabled className="btn-submit">
                      En cours&nbsp; <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isLoading && (
                    <button
                      className="btn-submit"
                      type="submit"
                      data-testid="btnAjouterId"
                    >
                      {item ? "Modifier" : "Ajouter"}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvisForm;
