import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";

import TeamApi from "../../../../../utils/api/team/team.api";
import { Team, TeamFormData } from "../../../../../utils/api/team/team.type";
import { COLORS } from "../../../../../utils/constants";
import { cleannerError, getAvatar } from "../../../../../utils/utils";

function useTeamForm(onHide: () => any, item?: Team) {
  const validationSchema = yup.object().shape({
    fullname: yup.string().required("Le nom est requis"),
    fonction: yup.string().required("La Fonction est requis"),
    avatar: item ? yup.mixed() : yup.mixed().required("L'image est requis"),
  });
  const [preview, setPreviw] = useState(
    item?.avatar ? getAvatar(item.avatar) : null
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm<TeamFormData>({
    resolver: yupResolver(validationSchema),
  });

  const handelImageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setPreviw(URL.createObjectURL(file));
      register("avatar");
      setValue("avatar", file);
    }
  }, []);

  //  Calling the Register Mutation
  const [addOrUpdate, { isLoading, isSuccess, error, isError }] =
    TeamApi.useAddTeamMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: item
          ? "Membre modifiée avec succès!"
          : "Membre ajoutée avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!item) {
          reset();
          setPreviw(null);
        }
        onHide();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur a survenue lors de ${
              item ? "la modification" : "l'ajout"
            }`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: TeamFormData) => {
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    addOrUpdate({ slug: item?.slug, data: fd });
  };

  useEffect(() => {
    if (item) {
      const fields: (keyof TeamFormData)[] = ["fullname", "fonction"];
      for (let field of fields) {
        register(field);
        setValue(field, item[field]);
      }
    }
  }, [item]);
  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    preview,
    handelImageChange,
  };
}

export default useTeamForm;
