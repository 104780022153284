import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import ConfigApi from '../../../../../utils/api/config/config.api'
import { Config } from '../../../../../utils/api/config/config.type'
import { COLORS } from '../../../../../utils/constants'
import { HtmlInput } from '../../Produits/AjoutProduitAdmin'

export default function GestionPoints() {
  const { data, isLoading } = ConfigApi.useGetConfigQuery()
  const [
    update,
    { isLoading: loading, isSuccess },
  ] = ConfigApi.useAddConfigMutation()
  const [formData, setFormData] = useState<Config>({
    slogan: '',
    description: '',
    email: '',
    facebook: '',
    id: undefined,
    linkedin: '',
    phone1: '',
    phone2: '',
    point: '',
    twitter: '',
    youtube: '',
    how_it_work: '',
    mission: '',
    team: '',
    value: '',
    title_why_one: '',
    description_why_one: '',
    title_why_two: '',
    description_why_two: '',
    title_why_three: '',
    description_why_three: '',
    image_rounded: null,
  })
  useEffect(() => {
    if (data) {
      setFormData(data)
    }
  }, [data])
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: 'success',
        title: 'Données modifiées avec succès!',
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isSuccess])
  const onChange = (name: keyof Config, value: string | number | File) => {
    setFormData((old) => ({ ...old, [name]: value }))
  }
  const changeHandler = (e) => {
    if (e.target.type === 'file') {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.files[0] }))
    } else {
      onChange(e.target.name, e.target.value)
    }
  }
  const inputs: {
    name: keyof Config
    label: string
    type?: 'number' | 'text' | 'email' | 'tel' | 'html' | 'file' | 'url'
  }[] = [
    { label: '1 point=', name: 'point', type: 'number' },
    { label: 'Email', name: 'email', type: 'email' },
    { label: 'Facebook', name: 'facebook' },
    { label: 'LinkedIn', name: 'linkedin' },
    { label: 'Twitter', name: 'twitter' },
    { label: 'Youtube', name: 'youtube' },
    { label: 'Téléphone', name: 'phone1', type: 'tel' },
    { label: 'Whatsapp', name: 'phone2', type: 'tel' },
    {
      label: 'Comment ça marche (url vidéo)',
      name: 'how_it_work',
      type: 'url',
    },
    {
      label: "Image d'accueil",
      name: 'image_rounded',
      type: 'file',
    },
    { label: 'Slogan de maajjal', name: 'slogan', type: 'html' },
    { label: 'Pourquoi Majjal (Titre card 1)', name: 'title_why_one' },
    {
      label: 'Pourquoi Majjal (Description card 1)',
      name: 'description_why_one',
      type: 'html',
    },
    { label: 'Pourquoi Majjal (Titre card 2)', name: 'title_why_two' },
    {
      label: 'Pourquoi Majjal (Description card 2)',
      name: 'description_why_two',
      type: 'html',
    },
    { label: 'Pourquoi Majjal (Titre card 3)', name: 'title_why_three' },
    {
      label: 'Pourquoi Majjal (Description card 3)',
      name: 'description_why_three',
      type: 'html',
    },
    { label: 'Description de maajjal', name: 'description', type: 'html' },
    { label: 'Nos valeurs', name: 'value', type: 'html' },
    { label: 'Notre mission', name: 'mission', type: 'html' },
    { label: 'Notre équipe', name: 'team', type: 'html' },
  ]

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(typeof formData['image_rounded'])
    if (typeof formData['image_rounded'] !== 'object') {
      delete formData.image_rounded
    }
    let fd: any = new FormData()

    Object.keys(formData).map((key) => {
      fd.append(key, formData[key])
    })

    await update({ slug: formData?.slug, data: fd })
  }
  if (isLoading) return <h4>Chargement...</h4>

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="row">
        {inputs.map(({ label, name, type = 'text' }) => {
          if (type !== 'html') {
            return (
              <div key={name} className="col-md-6 my-2">
                <div className="form-group">
                  <label htmlFor={name} className="form-send-point-label">
                    {label}
                  </label>
                  {type === 'file' ? (
                    <input
                      type={type}
                      onChange={changeHandler}
                      accept="image/*"
                      placeholder={label}
                      className="form-control-majjal form-control form-control-lg"
                      id={name}
                      name={name}
                    />
                  ) : (
                    <input
                      type={type}
                      onChange={changeHandler}
                      value={formData[name]}
                      placeholder={name === 'point' ? '10 FCFA' : label}
                      className="form-control-majjal form-control form-control-lg"
                      id={name}
                      name={name}
                    />
                  )}
                </div>
              </div>
            )
          } else {
            return (
              <div
                key={name}
                className={`${
                  name === 'description_why_one' ||
                  name === 'description_why_two' ||
                  name === 'description_why_three'
                    ? 'col-md-6'
                    : ''
                } form-group my-2`}
              >
                <label htmlFor={name} className="form-send-point-label mb-2">
                  {label}
                </label>
                <HtmlInput
                  data={formData[name]}
                  name={name}
                  onChange={(val) => onChange(name, val)}
                />
              </div>
            )
          }
        })}
      </div>

      <div className="btn-container flex-r mt-5">
        {loading && (
          <button type="submit" className="admin-submit-btn">
            Chargement...
          </button>
        )}
        {!loading && (
          <button type="submit" className="admin-submit-btn">
            Modifier
          </button>
        )}
      </div>
    </form>
  )
}
