import { useState } from "react";
import { FiMenu } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { onLogout } from "../../../../../redux/slices/user.slice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { getImage } from "../../../../../utils/utils";
import Logo from "../../../../assets/appImage/MaajjalAdminlogo.png";
import { getRoutePath } from "../../../../routes/routes";
import { useNotifications } from "../../../../shared/FrontHeader/FrontHeader";
import { IconChat, IconNotif } from "../../../../shared/Icons";
import VersionChecker from "../../../../utils/VersionChecker";
import { Routes } from "../AdminRoutes/AminRoutes";
import "./TopBar.css";

interface PropsType {
  index: number;
}
const TopBar = ({ index }: PropsType) => {
  const { user } = useAppSelector((s) => s.user);
  const isConnected = !!user?.id;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [active, setActive] = useState(index);
  const links = Routes;
  return (
    <div className="top-bar-admin">
      <div className="maajjal-card py-3">
        <div className="px-2 d-flex justify-content-between justify-content-md-end ">
          <button
            className="top-bar-menu d-md-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#menu-mobile"
            aria-expanded="false"
            aria-controls="menu-mobile"
          >
            <FiMenu />
          </button>
          <div className="auth-user-container gap-3">
            <Link to="/admin/messages" className="icon-notif-container">
              <IconChat />
              <div className="dot-indicatif"></div>
            </Link>
            <Notifications />
            {!isConnected ? null : (
              <>
                <div className="auth-user-image-container">
                  <img
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                    src={getImage(user?.avatar)}
                    alt="IconUser Account"
                  />
                </div>
                <button
                  className="btn dropdown-toggle cnt-dropdown-toggle"
                  type="button"
                  onClick={(e) => {
                    if (!isConnected) {
                      e.preventDefault();
                      navigate(getRoutePath("login"));
                    }
                  }}
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* <h3 className="auth-user-name">
														{user?.first_name + " " + user?.last_name}
													</h3> */}
                  <span className="dropdown-btn-logged-user-name-admin">
                    {user?.first_name + " " + user?.last_name}
                  </span>
                </button>
              </>
            )}

            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {isConnected && (
                <div className="text-center">
                  <button
                    onClick={() => {
                      dispatch(onLogout());
                      navigate(getRoutePath("home"));
                    }}
                    className="btn btn-logout-desktop pt-2"
                  >
                    <span className="item-nav-menu">Déconnexion</span>
                  </button>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="collapse d-md-none" id="menu-mobile">
        <div className="menu-mobile pb-3">
          <div className="sidebar-admin-mobile">
            <div className="logo-container flex-cc pt-4">
              <img src={Logo} alt="logo" />
              <div className="version-viewer-container admin-side">
                {"v" + VersionChecker()}
              </div>
            </div>
            <ul className="sidebar-admin-links mt-4 px-3">
              {links.map((link, index) => (
                <li
                  onClick={() => setActive(index)}
                  className={`${active === index && "active-admin-item"}`}
                  key={index}
                >
                  <Link
                    to={link.pathname}
                    className={`sidebar-admin-link  ${
                      active === index && "active-admin-link"
                    }`}
                  >
                    <span className="sidebar-icon-container">{link.icon}</span>
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;

function Notifications() {
  const [data, count, onShow, onClick] = useNotifications();

  return (
    <div className="dropdown-center">
      <button
        className="icon-notif-container"
        id="notificationDropdown"
        data-bs-toggle="dropdown"
        onClick={() => onShow()}
        aria-expanded="false"
      >
        <IconNotif />
        {count > 0 && (
          <span className="custom-position-absolute custom-position-absolute-admin translate-middle badge">
            {count}
          </span>
        )}
      </button>
      <ul className="dropdown-menu mt-2" aria-labelledby="notificationDropdown">
        {data.length === 0 && (
          <li className="dropdown-item cursor-pointer">Pas de notification</li>
        )}
        {data.map((item) => (
          <li
            className="dropdown-item custom-dropdown-item py-2"
            onClick={() => onClick(item)}
            key={item.id}
          >
            {item.text}
          </li>
        ))}
      </ul>
    </div>
  );
}
