import React from "react";
import { Link } from "react-router-dom";
import "../common/Auth.css";
import AuthPanel from "../common/AuthPanel";
import AuthSocialLogin from "../common/AuthSocialLogin";
import UseLoginForm from "../UseAuthForm/UseLoginForm";

const Login: React.FC = () => {
  const { register, errors, onSubmit, isLoading, clearErrors } = UseLoginForm();

  return (
    <div className="auth-component">
      <div className="row empty-header no-view-mobile"></div>
      <div className="auth-container py-5">
        <div className="auth-majjal-container">
          <div className="row">
            <AuthPanel />
            <div className="col-md-8 auth-panel-right px-4 py-md-5 auth-majjal-panel-right">
              <h1 className="auth-title">Connexion</h1>
              <AuthSocialLogin />
              <div className="auth-form-container mt-4">
                <form onSubmit={onSubmit}>
                  <div className="form-group mb-4">
                    <input
                      type="email"
                      className="majjal-form-control form-control form-control-lg"
                      placeholder="Adresse mail"
                      {...register("email")}
                      data-testid="emailId"
                    />
                    {errors.email && (
                      <div className="alert alert-danger sia-alert-danger closer mt-2">
                        {errors.email?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="password"
                      className="majjal-form-control form-control form-control-lg"
                      placeholder="Mot de passe"
                      // error={errors.password}
                      {...register("password")}
                      data-testid="passwordId"
                    />
                    {errors.password && (
                      <div className="alert alert-danger sia-alert-danger closer mt-2">
                        {errors.password?.message}
                      </div>
                    )}
                  </div>
                  <div className="mb-5 flex-csb">
                    <div className="form-group flex-cc gap-2">
                      <input
                        type="checkbox"
                        className="form-check-input form-auth-check"
                        placeholder="Mot de passe"
                        name="remember-me"
                        onChange={(e) => {
                          localStorage.setItem(
                            "remember",
                            e.target.checked ? "1" : "0"
                          );
                        }}
                        id="remember-me"
                      />
                      <label htmlFor="remember-me" className="remember-me">
                        Se rappeler de moi
                      </label>
                    </div>
                    <Link
                      to="/recuperation-mot-de-passe"
                      className="forget-password-link"
                    >
                      Mot de passe oublié?
                    </Link>
                  </div>
                  <div className="btn-submit-form-container mb-3">
                    {!isLoading && (
                      <button
                        type="submit"
                        className="btn-theme-blue w-100"
                        data-testid="submitBtnId"
                      >
                        Se connecter
                      </button>
                    )}
                    {isLoading && (
                      <button
                        type="button"
                        disabled
                        className="btn-theme-blue w-100"
                      >
                        Chargement...
                      </button>
                    )}
                  </div>
                  <div className="flex-r gap-2">
                    <p className="auth-link-message">
                      Vous n’avez pas de compte?
                    </p>
                    <Link
                      to="/s'inscrire"
                      className="register-link"
                      // loading={isLoading}
                    >
                      S’inscrire
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
