import { NavLink } from "react-router-dom";
import { useDashboardByAdminQuery } from "../../../../utils/api/user/user.api";
import { formatAmout } from "../../../../utils/utils";
import ImgBag from "../../../assets/icons/Bag.png";
import ImgWallet from "../../../assets/icons/Wallet.png";
import DashboardTable from "../../Tables/admin/dashboard/DashboardTable";
import SideBarAdmin from "../Layouts/SideBarAdmin/SideBarAdmin";
import TopBar from "../Layouts/TopBar/TopBar";
import "./DashboardAdmin.css";

const DashboardAdmin = () => {
  const {
    data = {
      amount_invest: 0,
      data: [],
      nb_commandes: 0,
      nb_produit: 0,
      nb_projet: 0,
      nb_user: 0,
    },
    isLoading,
  } = useDashboardByAdminQuery();
  const top10 = data.data.slice(0, 10);
  return (
    <div className="parametre-page">
      <TopBar index={0} />
      <SideBarAdmin index={0} />
      <div className="parametre-component m-tableau-bord pt-3 flex-r">
        <div className="admin-page-container">
          <div className="px-2 py-2 p-xxl-3">
            <section className="mb-4">
              <div className="row maajaal-admin-dashboard-card-container">
                <div className="col-lg-4">
                  <div className="card card-body maajaal-admin-dashboard-card1 ">
                    <div className="ma-dashboard-card-title-container">
                      <div className="ma-dashboard-card-title">
                        Total des fonds levés
                      </div>
                      <div className="ma-dashboard-card-subtitle">
                        Cette semaine
                      </div>
                    </div>
                    <div className="ma-dashboard-card-price">
                      {formatAmout(data.amount_invest)}
                    </div>
                    <div className="ma-dashboard-card-icon">
                      <img
                        src={ImgWallet}
                        alt="icon"
                        className="ma-dashboard-card-icon-wallet ma-dashboard-card-icon-wallet-custom"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card card-body maajaal-admin-dashboard-card2 ">
                    <div className="ma-dashboard-card-title-container">
                      <div className="ma-dashboard-card-title">
                        Nombre de projet{" "}
                      </div>
                      <div className="ma-dashboard-card-subtitle">
                        Cette semaine
                      </div>
                    </div>
                    <div className="ma-dashboard-card-price">
                      {data.nb_projet}
                    </div>
                    <div className="ma-dashboard-card-icon">
                      <img
                        src={ImgBag}
                        alt="Bag Count"
                        className="ma-dashboard-card-icon-wallet ma-dashboard-card-icon-wallet-custom"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card card-body maajaal-admin-dashboard-card3 ">
                    <div className="ma-dashboard-card-title-container">
                      <div className="ma-dashboard-card-title">
                        Nombre d’utilisateurs
                      </div>
                      <div className="ma-dashboard-card-subtitle">
                        Cette semaine
                      </div>
                    </div>
                    <div className="ma-dashboard-card-price">
                      {data.nb_user}
                    </div>
                    <div className="ma-dashboard-card-icon">
                      <img
                        src={ImgWallet}
                        alt="icon"
                        className="ma-dashboard-card-icon-wallet ma-dashboard-card-icon-wallet-custom"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row maajaal-admin-dashboard-card-container">
                <div className="col-lg-4">
                  <div className="card card-body maajaal-admin-dashboard-card4 ">
                    <div className="ma-dashboard-card-title-container">
                      <div className="ma-dashboard-card-title">
                        Nombre de produit
                      </div>
                      <div className="ma-dashboard-card-subtitle">
                        Cette semaine
                      </div>
                    </div>
                    <div className="ma-dashboard-card-price">
                      {data.nb_produit}
                    </div>
                    <div className="ma-dashboard-card-icon">
                      <img
                        src={ImgWallet}
                        alt="Wallet Count"
                        className="ma-dashboard-card-icon-wallet ma-dashboard-card-icon-wallet-custom"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card card-body maajaal-admin-dashboard-card5 ">
                    <div className="ma-dashboard-card-title-container">
                      <div className="ma-dashboard-card-title">
                        Nombre de commandes{" "}
                      </div>
                      <div className="ma-dashboard-card-subtitle">
                        Cette semaine
                      </div>
                    </div>
                    <div className="ma-dashboard-card-price">
                      {data.nb_commandes}
                    </div>
                    <div className="ma-dashboard-card-icon">
                      <img
                        src={ImgBag}
                        alt="Bag Stat"
                        className="ma-dashboard-card-icon-wallet ma-dashboard-card-icon-wallet-custom"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4"></div>
              </div>
            </section>

            <div className="col-md-12 maajjal-table-card px-3 py-4">
              <div className="ma-dashboard-table-title-container">
                <h3 className="ma-dashboard-table-title mb-4">
                  Top donnateurs
                </h3>
                <NavLink
                  to={"/admin/liste-donnateurs"}
                  type="button"
                  className="btn ma-dashboard-table-btn"
                >
                  Voir tous les donateurs
                </NavLink>
              </div>
              <DashboardTable data={top10} isLoading={isLoading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardAdmin;
