import React from 'react'
import Skeleton from 'react-loading-skeleton'

function MarketPlaceSkeleton() {
  return (
    <div className="col pt-3">
      <div className="card market-place-card pt-2 px-1 position-relative mb-4">
          <div className="flex-cc">
            <Skeleton
                height={120}
                width={120}
                circle={false}
             />
          </div>
        <div className="card-body">
          <div className="flex-c card-market-title">
            <h5 className="market-title mb-2">
                <Skeleton
                    height={10}
                    width={100}
                    circle={false}
                />
            </h5>
          </div>
          <div className="flex-sb ">
            <div className="flex-c gap-1 ">
                <h6 className="normal-price">
                <Skeleton
                    height={10}
                    width={160}
                    circle={false}
                />
                </h6>
            </div>
          </div>

            <div className='w-100'>
                <Skeleton
                    height={30}
                    circle={false}
                    borderRadius='0.35rem'
                />
            </div>
        </div>
      </div>
    </div>
  )
}

export default MarketPlaceSkeleton