import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { FiChevronRight, FiChevronUp } from "react-icons/fi";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../redux/store";
import AchatApi, {
  useAchatsByUserQuery,
} from "../../../../utils/api/achat/achat.api";
import { Achat } from "../../../../utils/api/achat/achat.type";
import { COLORS, ORDER_STATUT } from "../../../../utils/constants";
import { formatAmout } from "../../../../utils/utils";
import MesAchatsDetails from "../../TableauDeBord/mesCommandes/MesAchatsDetails";
import MesAchatsSkeleton from "./MesAchatsSkeleton";

function MesAchatsTable() {
  const userId = useAppSelector((s) => s.user.user?.id as number);
  const [addComplaint] = AchatApi.useComplaintMutation();
  const { data = [], isLoading } = useAchatsByUserQuery(userId);
  const onSend = (achat: Achat) => {
    Swal.fire({
      title: `Envoyer une réclamation`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Envoyer",
      cancelButtonText: "Annuler",
      showLoaderOnConfirm: true,
      input: "text",
      inputLabel: "Motif",
      inputPlaceholder: "Motif",
      customClass: {
        confirmButton: "swal-custom-btn mx-2",
        cancelButton: "swal-custom-btn  mx-2",
      },

      preConfirm: async (motif: string) => {
        const res = await addComplaint({
          achat: achat.id,
          user: userId,
          motif,
        });
        // @ts-ignore
        if (res?.data) {
          Swal.fire({
            icon: "success",
            title: "Réclamation envoyé avec succès",
            iconColor: COLORS.blue,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Réclamation non envoyé",
            iconColor: COLORS.red,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      },
    });
  };

  const etatFormat = (cell: any, row: Achat) => {
    const statut = ORDER_STATUT[row.status || "en_attente"];
    return (
      <>
        <div
          className="etat-commande-non-valide"
          style={{ backgroundColor: statut.color }}
        >
          <span className="text-statut-format">{statut.label}</span>
        </div>
      </>
    );
  };

  const dateFormatter = (cell: any, row: any) => {
    return <span>{moment(cell).format("DD/MM/YYYY")}</span>;
  };

  const columns = [
    {
      dataField: "code",
      text: "N° Commande",
      style: {
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell: any, row: any) => dateFormatter(cell, row),
      style: {
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "produit.name",
      text: "Nom du produit",
      style: {
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "produit.commercant",
      formatter: (cell) => `${cell.first_name} ${cell.last_name}`,
      text: "Commerçant",
      style: {
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "montant_total",
      text: "Montant total",
      formatter: (cell) => formatAmout(cell),
      style: {
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "status",
      text: "Statut de la commande",
      formatter: (cell: any, row: any) => etatFormat(cell, row),
      style: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "id",
      text: "Réclamation",
      formatter: (cell: any, row: Achat) => {
        if (!["livrer", "payer"].includes(row.status)) {
          return null;
        }
        return (
          <button
            onClick={() => onSend(row)}
            style={{
              fontSize: 12,
              padding: 5,
              paddingLeft: 10,
              paddingRight: 10,
            }}
            className="admin-submit-btn"
          >
            Réclamer
          </button>
        );
      },
      headerStyle: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
  ];

  const expandRow = {
    renderer: (row) => <MesAchatsDetails order={row} />,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <b className="d-none">-</b>;
      }
      return <b className="d-none">+</b>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <FiChevronUp className="dashboard-commandes-icon-collapse" />
          </b>
        );
      }
      return (
        <b>
          <FiChevronRight className="dashboard-commandes-icon-collapse" />
        </b>
      );
    },
  };

  const pageButtonRenderer = ({ page, active, onPageChange }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    let activeStyle: any = {};
    if (active) {
      activeStyle.backgroundColor = "#0FBF56";
      activeStyle.color = "#FFFFFF";
      activeStyle.padding = "10px 15px";
      activeStyle.borderRadius = "10px";
      activeStyle.margin = "0px 5px";
      activeStyle.textDecoration = "none";
    } else {
      activeStyle.backgroundColor = "#FFFFFF";
      activeStyle.color = "rgba(0, 0, 0, 0.7)";
      activeStyle.border = "1px solid #0FBF56";
      activeStyle.padding = "10px 15px";
      activeStyle.borderRadius = "10px";
      activeStyle.margin = "0px 5px";
      activeStyle.textDecoration = "none";
    }
    if (typeof page === "string") {
      activeStyle.backgroundColor = "white";
      activeStyle.color = "black";
    }

    return (
      <li className="page-item my-5">
        <a href="#/" onClick={handleClick} style={activeStyle}>
          {page}
        </a>
      </li>
    );
  };

  const options = {
    pageButtonRenderer,
    prePageText: "Précédent",
    nextPageText: "Suivant",
  };

  return (
    <>
      {isLoading && <MesAchatsSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          bordered={false}
          condensed={false}
          responsive
          wrapperClasses="table-responsive"
          expandRow={expandRow}
          pagination={paginationFactory(options)}
        />
      )}
    </>
  );
}

export default MesAchatsTable;
