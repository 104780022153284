import { useEffect } from "react";
import Swal from "sweetalert2";
import { useUpdateAchatMutation } from "../../../../utils/api/achat/achat.api";
import { Achat } from "../../../../utils/api/achat/achat.type";
import { COLORS } from "../../../../utils/constants";
import { formatMontant, getImage } from "../../../../utils/utils";

function CommandeDetails({ order }: { order: Achat }) {
  const [updateAchat, { isLoading, isSuccess }] = useUpdateAchatMutation();
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Commande validée avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [isSuccess]);
  return (
    <div className="dashboard-commande-detail-container">
      <div className="dashboard-commande-detail-produit-div">
        <div className="dashboard-commande-detail-produi-title">Produit</div>
        <div className="row g-3">
          <div className="col-auto">
            <img
              src={getImage(order.produit?.avatar)}
              className="dashboard-commande-detail-produit-img"
              alt="..."
            />
          </div>
          <div className="col-auto">
            <div className="dashboard-commande-detail-produit-quantite-container">
              <div className="dashboard-commande-detail-produit-quantite">
                1 x {order?.produit?.name}
              </div>
              <div className="dashboard-commande-detail-produit-quantite">
                {formatMontant(order?.montant_total)}
              </div>
            </div>
            <div className="dashboard-commande-detail-produit-quantite-container2">
              <div className="dashboard-commande-detail-produit-quantite2">
                Total
              </div>
              <div className="dashboard-commande-detail-produit-quantite2">
                {formatMontant(order?.montant_total)}
              </div>
            </div>
          </div>
          <div className="col-auto"></div>
        </div>
      </div>
      <div className="dashboard-commande-detail-produit-div">
        <div className="dashboard-commande-detail-produi-title">
          Informations
        </div>
        <div className="row">
          <div className="col-6">
            <div className="dashboard-commande-detail-produit-information-nom">
              Nom du client :
            </div>
          </div>
          <div className="col-6">
            <div className="dashboard-commande-detail-produit-information-nom">
              {order.user_id?.first_name} {order.user_id?.last_name}
            </div>
          </div>
          <div className="col-6">
            <div className="dashboard-commande-detail-produit-information-nom">
              Adresse :
            </div>
          </div>
          <div className="col-6">
            <div className="dashboard-commande-detail-produit-information-nom">
              {order.adresse_livraison}
            </div>
          </div>
          <div className="col-6">
            <div className="dashboard-commande-detail-produit-information-nom">
              Téléphone :
            </div>
          </div>
          <div className="col-6">
            <div className="dashboard-commande-detail-produit-information-nom">
              {order.user_id?.telephone}
            </div>
          </div>
        </div>
      </div>
      {order.status === "en_attente" && (
        <div className="dashboard-commande-detail-btn-div">
          {isLoading && (
            <button type="button" className="btn dashboard-commande-detail-btn">
              Validation en cours...
            </button>
          )}
          {!isLoading && (
            <button
              onClick={() =>
                updateAchat({ id: order.id, data: { status: "valide" } })
              }
              type="button"
              className="btn dashboard-commande-detail-btn"
            >
              Valider la commande
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default CommandeDetails;
