import { useState } from 'react'
import { useAppSelector } from '../../../../../redux/store'
import {
  useGetFaqQuery,
  useLazyFaqIncrementQuery,
} from '../../../../../utils/api/faq/faq.api'
import { Projet } from '../../../../../utils/api/launchCampagn/projet.type'
import Pagination from '../../../../shared/Pagination'
import UseAddQuestionForm from './UseAddQuestionForm'

function Faq({ projet }: { projet?: Projet }) {
  const { user } = useAppSelector((s) => s.user)
  const isConnected = !!user?.id
  const isAdmin = user?.user_type === 'admin'
  const [page, setPage] = useState(1)
  const [increment] = useLazyFaqIncrementQuery()
  const [incs, setIncs] = useState<number[]>([])
  const { data = { results: [] } } = useGetFaqQuery({ page })
  const { register, onSubmit, errors, isLoading } = UseAddQuestionForm(
    projet?.id,
  )

  return (
    <div className="campagne-detail-projet-description-container">
      <section>
        <h3 className="campagne-detail-projet-faq-title">Faq</h3>
        {isConnected || isAdmin ? null : (
          <>
            <div className="campagne-detail-projet-faq-info-text">
              Posez toutes vos questions qui concernent le projet
            </div>
            <div className="campagne-detail-projet-formulaire-faq ">
              <form className="row" onSubmit={onSubmit}>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control mb-3 mb-lg-0 campagne-detail-projet-formulaire-input-faq"
                    id="inputPassword2"
                    placeholder="Posez toutes vos questions qui concernent le projet"
                    {...register('question')}
                  />
                  {errors.question && (
                    <div className="alert alert-danger sia-alert-danger closer mt-2">
                      {errors.question?.message}
                    </div>
                  )}
                </div>

                <div className="col-lg-4">
                  {isLoading && (
                    <button
                      disabled
                      className="btn mb-3 campagne-detail-projet-formulaire-buton-faq"
                    >
                      En cours&nbsp; <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isLoading && (
                    <button
                      type="submit"
                      className="btn mb-3 campagne-detail-projet-formulaire-buton-faq"
                    >
                      Envoyer
                    </button>
                  )}
                </div>
              </form>
            </div>
          </>
        )}
      </section>
      {data.results.length > 0 && (
        <section className="campagne-faq-section">
          <h3 className="campagne-detail-projet-faq-title">
            Questions/Réponses récentes
          </h3>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {data.results.map((faq) => {
              const jsId1 = 'collapse' + faq.id
              const jsId = 'collapseed' + faq.id
              return (
                <div
                  key={jsId1}
                  className="accordion-item accordion-item-detail-projet"
                >
                  <h2 className="accordion-header" id={jsId1}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${jsId}`}
                      aria-expanded="false"
                      aria-controls={jsId1}
                      onClick={() => {
                        if (!incs.includes(faq.id)) {
                          increment(faq.id)
                          setIncs((old) => [...old, faq.id])
                        }
                      }}
                    >
                      {faq.question}
                    </button>
                  </h2>
                  <div
                    id={jsId}
                    className="accordion-collapse collapse"
                    aria-labelledby={jsId}
                    data-bs-parent="#accordionFlushExample"
                  >
                    {faq.responses.length === 0 && (
                      <div className="accordion-body">
                        Pas encore de réponse
                      </div>
                    )}
                    {faq.responses.map((res) => (
                      <div key={res.id} className="accordion-body">
                        {res.reponse}
                      </div>
                    ))}
                  </div>
                </div>
              )
            })}
          </div>
        </section>
      )}
      <Pagination onPageChange={setPage} page={page} total={data.count} />
    </div>
  )
}

export default Faq
