import React from 'react'
import { MontantConfig } from '../../../../utils/api/montantConfig/montantConfig.type'
import BtnCloseModal from '../../../shared/BtnCloseModal'
import BtnSubmit from '../../../shared/BtnSubmit'
import UseAddMontantConfig from '../../admin/Parametre/ParametresTabs/requestAdmin/UseAddMontantConfig'

type PropsType = {
  item?: MontantConfig
  modalId: string
}
function AddMontantModal({ item, modalId }: PropsType) {
  const {
    register,
    setValue,
    formState,
    onSubmit,
    isLoading,
    reset,
    setIdModal,
  } = UseAddMontantConfig()

  React.useEffect(() => {
    if (modalId) {
      setIdModal(modalId)
    }
  }, [modalId])
  React.useEffect(() => {
    if (item?.id) {
      setValue('slug', item?.slug)
      setValue('montant', parseInt(item?.montant))
    }
  }, [item])
  const handleClick = () => {
    if (!item?.id) {
      reset()
    }
  }
  return (
    <div className="modal fade" id={modalId}>
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content py-3 px-2 px-xl-4">
          <div className="flex-csb mb-4">
            <h1 className="admin-modal-title m-0">
              {item?.id ? 'Modifier' : 'Ajouter'} un montant minimum à investir
            </h1>
            <BtnCloseModal onClick={() => handleClick()} />
          </div>
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-12 form-group mb-3">
                <label htmlFor="montant" className="admin-form-label">
                  Montant
                </label>
                <input
                  type="number"
                  id="montant"
                  className="form-control admin-form-control"
                  placeholder="Montant"
                  {...register('montant')}
                />
                {formState?.errors.montant && (
                  <div className="alert alert-danger sia-alert-danger closer">
                    {formState?.errors.montant?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="btn-container flex-r mt-5">
              {isLoading && (
                <button disabled className="admin-submit-btn">
                  En cours&nbsp; <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
              {!isLoading && (
                <BtnSubmit label={item?.id ? 'Modifier' : 'Ajouter'} />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddMontantModal
