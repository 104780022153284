import React, { useEffect } from "react";
import CustomAuthPanel from "../common/CustomAuthPanel";
import UseResetPasswordForm from "../UseAuthForm/UseResetPasswordForm";

function ResetPassword() {
  const { register, errors, onSubmit, isLoading, setError } =
    UseResetPasswordForm();

  useEffect(() => {
    if (errors) {
      errors?.email?.message === "Email must be a valid email" &&
        setError("email", { message: "L'email doit être valide" });
    }
  }, [errors.email]);
  return (
    <div className="auth-component">
      <div className="row empty-header no-view-mobile"></div>
      <div className="auth-container py-5">
        <div className="auth-majjal-container">
          <div className="row">
            <CustomAuthPanel />
            <div className="col-md-8 auth-panel-right px-4 py-md-5 auth-majjal-panel-right">
              <h1 className="auth-title">Réinitialisez votre mot de passe</h1>
              <div className="auth-recuperation-pass-description mb-5">
                Veuillez entrer le code reçu par mail et votre nouveau mot de
                passe
              </div>
              <div className="auth-form-container mt-4">
                <form onSubmit={onSubmit}>
                  <div className="form-group mb-4">
                    <input
                      type="number"
                      className="majjal-form-control form-control form-control-lg"
                      placeholder="Code"
                      {...register("code")}
                      data-testid="codeId"
                    />
                    {errors.code && (
                      <div className="alert alert-danger sia-alert-danger closer mt-2">
                        {errors.code?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-4">
                    <input
                      type="email"
                      className="majjal-form-control form-control form-control-lg"
                      placeholder="Adresse mail"
                      {...register("email")}
                      data-testid="emailId"
                    />
                    {errors.email && (
                      <div className="alert alert-danger sia-alert-danger closer mt-2">
                        {errors.email?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-4">
                    <input
                      type="password"
                      className="majjal-form-control form-control form-control-lg"
                      placeholder="Nouveau mot de passe"
                      {...register("new_password")}
                      data-testid="new_passwordId"
                    />
                    {errors.new_password && (
                      <div className="alert alert-danger sia-alert-danger closer mt-2">
                        {errors.new_password?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-4">
                    <input
                      type="password"
                      className="majjal-form-control form-control form-control-lg"
                      placeholder="Confirmer le mot de passe"
                      {...register("new_password_confirm")}
                      data-testid="new_password_confirmId"
                    />
                    {errors.new_password_confirm && (
                      <div className="alert alert-danger sia-alert-danger closer mt-2">
                        {errors.new_password_confirm?.message}
                      </div>
                    )}
                  </div>
                  <div className="btn-submit-form-container mb-3">
                    {!isLoading && (
                      <button
                        type="submit"
                        className="btn-theme-blue w-100"
                        data-testid="submitBtnId"
                      >
                        RÉINITIALISER
                      </button>
                    )}
                    {isLoading && (
                      <button
                        type="button"
                        disabled
                        className="btn-theme-blue w-100"
                      >
                        Chargement...
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
