import { useEffect, useState } from "react";
import { Achat } from "../../../../utils/api/achat/achat.type";
import {
  formatMontant,
  getImage,
  useLocationState,
} from "../../../../utils/utils";
import GoBackBtn from "../../../shared/GoBackBtn";
import SideBarAdmin from "../Layouts/SideBarAdmin/SideBarAdmin";
import TopBar from "../Layouts/TopBar/TopBar";
import "./CommandesAdmin.css";

function DetailsCommandeAdmin() {
  const [commande, setCommande] = useState<Achat>();
  const itemState = useLocationState<any>(undefined);
  useEffect(() => {
    if (itemState?.commande) {
      setCommande(itemState.commande);
    }
  }, [itemState]);
  return (
    <div className="parametre-page">
      <TopBar index={6} />
      <SideBarAdmin index={6} />
      <div className="parametre-component m-tableau-bord pt-3 flex-r">
        <div className="admin-page-container">
          <div className="p4 p-xxl-3">
            <GoBackBtn
              className="mad-donnateur-infos-perso-boutton-back"
              label="Retour"
            />
            <div className="row">
              <div className="col-md-12 maajjal-table-card px-3 py-4 ml-2 mr-3">
                <div className="dashboard-commande-detail-container">
                  <div className="dashboard-commande-detail-produit-div">
                    <div className="dashboard-commande-detail-produi-title">
                      Produit
                    </div>
                    <div className="row g-3">
                      <div className="col-auto">
                        <img
                          src={getImage(commande?.produit?.avatar)}
                          className="dashboard-commande-detail-produit-img"
                          alt={commande?.produit?.name}
                        />
                      </div>
                      <div className="col-auto">
                        <div className="dashboard-commande-detail-produit-quantite-container">
                          <div className="dashboard-commande-detail-produit-quantite">
                            1 x {commande?.produit?.name}
                          </div>
                          <div className="dashboard-commande-detail-produit-quantite">
                            {formatMontant(commande?.produit?.price_discount)}
                          </div>
                        </div>
                        <div className="dashboard-commande-detail-produit-quantite-container2">
                          <div className="dashboard-commande-detail-produit-quantite2">
                            Total
                          </div>
                          <div className="dashboard-commande-detail-produit-quantite2">
                            {formatMontant(commande?.montant_total)}
                          </div>
                        </div>
                      </div>
                      <div className="col-auto"></div>
                    </div>
                  </div>
                  <div className="dashboard-commande-detail-produit-div">
                    <div className="dashboard-commande-detail-produi-title">
                      Informations client
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="admin-commande-detail-produit-information-nom-container">
                          <div className="dashboard-commande-detail-produit-information-nom">
                            Nom du client :
                          </div>
                          <div className="dashboard-commande-detail-produit-information-nom">
                            {commande?.user_id?.first_name}{" "}
                            {commande?.user_id?.last_name}
                          </div>
                        </div>
                        <div className="admin-commande-detail-produit-information-nom-container">
                          <div className="dashboard-commande-detail-produit-information-nom">
                            Telephone :
                          </div>
                          <div className="dashboard-commande-detail-produit-information-nom">
                            {commande?.user_id?.phone}
                          </div>
                        </div>
                        <div className="admin-commande-detail-produit-information-nom-container">
                          <div className="dashboard-commande-detail-produit-information-nom">
                            Adresse de livraison
                          </div>
                          <div className="dashboard-commande-detail-produit-information-nom">
                            {commande?.adresse_livraison}
                          </div>
                        </div>
                        <div className="admin-commande-detail-produit-information-nom-container">
                          <div className="dashboard-commande-detail-produit-information-nom">
                            Email :
                          </div>
                          <div className="dashboard-commande-detail-produit-information-nom">
                            {commande?.user_id?.email}
                          </div>
                        </div>
                        <div className="admin-commande-detail-produit-information-nom-container">
                          <div className="dashboard-commande-detail-produit-information-nom">
                            Mode de livraison :
                          </div>
                          <div className="dashboard-commande-detail-produit-information-nom">
                            {commande?.livraison_mode}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="dashboard-commande-detail-produi-title admin-commande-detail-produi-title">
                      Informations commerçant
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="admin-commande-detail-produit-information-nom-container">
                          <div className="dashboard-commande-detail-produit-information-nom">
                            Nom du commercant :
                          </div>
                          <div className="dashboard-commande-detail-produit-information-nom2">
                            {commande?.produit?.commercant?.first_name}{" "}
                            {commande?.produit?.commercant?.last_name}
                          </div>
                        </div>
                        <div className="admin-commande-detail-produit-information-nom-container">
                          <div className="dashboard-commande-detail-produit-information-nom">
                            Telephone :
                          </div>
                          <div className="dashboard-commande-detail-produit-information-nom2">
                            {commande?.produit?.commercant?.phone}
                          </div>
                        </div>
                        <div className="admin-commande-detail-produit-information-nom-container">
                          <div className="dashboard-commande-detail-produit-information-nom">
                            Adresse :
                          </div>
                          <div className="dashboard-commande-detail-produit-information-nom2">
                            {commande?.produit?.commercant?.adress}
                          </div>
                        </div>
                        <div className="admin-commande-detail-produit-information-nom-container">
                          <div className="dashboard-commande-detail-produit-information-nom">
                            Email :
                          </div>
                          <div className="dashboard-commande-detail-produit-information-nom2">
                            {commande?.produit?.commercant?.email}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsCommandeAdmin;
