import moment from "moment";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  useDeleteProduitMutation,
  useGetProductsQuery,
} from "../../../../../utils/api/produit/produit.api";
import { COLORS } from "../../../../../utils/constants";
import { formatAmout } from "../../../../../utils/utils";
import Pagination from "../../../../shared/Pagination";
import ProduitsAdminSkeleton from "./ProduitsAdminSkeleton";

function ProduitsAdminTable({
  word1,
  word2,
}: {
  word1: string;
  word2: string;
}) {
  const [page, setPage] = useState(1);
  useEffect(() => {
    setPage(1);
  }, [word1, word2]);
  const { isLoading, data = { results: [] } } = useGetProductsQuery({
    page,
    wordproduit: word1,
    wordcommercant: word2,
  });
  const [deleteProduit, { isError, error, isSuccess }] =
    useDeleteProduitMutation();
  const navigate = useNavigate();

  const onDeleteProduct = (slug) => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir supprimer ce produit",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      customClass: {
        confirmButton: "swal-custom-btn mx-2",
        cancelButton: "swal-custom-btn  mx-2",
      },
      preConfirm: () => {
        return deleteProduit(slug);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
      }
    });
  };
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Produit supprimé avec succès",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        navigate("/admin/produits", { replace: false });
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const err = error as any;
      console.log(err);

      Swal.fire({
        icon: "error",
        title: `Une erreur de status ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [isError]);

  const actionFormatter: any = (cell, row) => {
    return (
      <div className="table-actions-btn-container-projet">
        <NavLink
          to="/admin/details-produits"
          className="btn btn-action-user-admin"
          data-testid="btnId"
          state={row}
        >
          <AiOutlineEye className="edit-btn" />
        </NavLink>
        <NavLink
          to="/admin/modifier-produits"
          className="btn btn-action-user-admin"
          data-testid="btnId"
          state={row}
        >
          <AiOutlineEdit className="edit-btn" />
        </NavLink>
        <FiTrash2
          className="voir-btn"
          onClick={() => onDeleteProduct(row?.id)}
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "N°",
    },
    {
      dataField: "name",
      text: " Produit",
    },
    {
      dataField: "prix",
      text: "Prix",
      formatter: (cell, row) => formatAmout(cell),
    },
    {
      dataField: "discount",
      text: "Discount",
      formatter: (cell, row) => cell + "%",
    },
    {
      dataField: "point",
      text: "Point",
    },
    {
      dataField: "commercant",
      text: "Commercant",
      formatter: (cell, row) => cell?.first_name + " " + cell?.last_name,
    },
    {
      dataField: "created_at",
      text: "Date de création",
      formatter: (cell, row) => moment(cell).format("DD/MM/YYYY"),
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <ProduitsAdminSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={data.results}
          columns={columns}
          striped
          noDataIndication="Aucune donnée disponible"
          bordered={false}
          condensed={false}
          responsive
          wrapperClasses="table-responsive"
          rowStyle={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "21px",
            color: "rgba(0, 0, 0, 0.7)",
          }}
          headerClasses="header-class"
        />
      )}
      <Pagination page={page} onPageChange={setPage} total={data.count} />
    </>
  );
}

export default ProduitsAdminTable;
