import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiOutlineEdit } from "react-icons/ai";
import { FaPlusCircle } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import Switch from "react-switch";
import {
  useAddProjetMutation,
  useProjetsQuery,
} from "../../../../../utils/api/launchCampagn/projet.api";
import { Projet } from "../../../../../utils/api/launchCampagn/projet.type";
import { formatAmout } from "../../../../../utils/utils";
import listprojetadmProgressImg1 from "../../../../assets/icons/listprojetadmProgressImg1.png";
import listprojetadmProgressImg2 from "../../../../assets/icons/listprojetadmProgressImg2.png";
import InvestissementModal from "../../../../modales/InvestissementModal";
import { getRoutePath } from "../../../../routes/routes";
import Pagination from "../../../../shared/Pagination";
import { DeleteProjetButton } from "../../../TableauDeBord/mesprojets/DetailsProjet/DetailsProjet";
import ProjetsAdminSkeleton from "./ProjetsAdminSkeleton";
function ProjetsAdminTable({ word1, word2 }: { word1: string; word2: string }) {
  const [page, setPage] = useState(1);
  const { data = { results: [] }, isLoading } = useProjetsQuery({
    page,
    wordprojet: word1,
    wordporteur: word2,
  });
  useEffect(() => {
    setPage(1);
  }, [word1, word2]);

  const progressionFormat = (cell: any, row: Projet) => {
    if (row.pourcentage >= 50)
      return (
        <>
          <div className="progression-projet-format-plein">
            <img src={listprojetadmProgressImg1} alt="" className="" />
            <span className="text-statut-format">
              {`${Math.ceil(row.pourcentage)}%`}
            </span>
          </div>
        </>
      );
    return (
      <>
        <div className="progression-projet-format-restant">
          <img src={listprojetadmProgressImg2} alt="" className="" />
          <span className="text-statut-format">
            {`${Math.ceil(row.pourcentage)}%`}
          </span>
        </div>
      </>
    );
  };
  const statutFormat = (cell: any, row: any) => {
    return <SwitchProjet item={row} />;
  };

  const actionFormatter: any = (cell, row) => {
    const modalId = `AddDOn${row.id}`;
    return (
      <div className="table-actions-btn-container-projet">
        <NavLink
          className="action-btn "
          title="Voir"
          state={row}
          to={getRoutePath("DetailsProjetAdmin", { slug: row.slug })}
        >
          <FiEye className="voir-btn" />
        </NavLink>
        <NavLink
          className="action-btn "
          title="Modifier"
          state={row}
          to={getRoutePath("UpdateProject", { slug: row.slug })}
        >
          <AiOutlineEdit className="edit-btn" />
        </NavLink>
        <DeleteProjetButton
          modalId={`DeleteProject${row.id}`}
          item={row}
          className="page-detail-projet-tab-icon"
          iconClassName={`supprimer-btn`}
        />
        <NavLink
          className="action-btn "
          title="Ajouter un don"
          data-bs-toggle="modal"
          data-bs-target={`#${modalId}`}
          to={"#"}
        >
          <FaPlusCircle className="edit-btn" />
        </NavLink>
        <InvestissementModal
          modalId={modalId}
          projet={row}
          admin
          type={undefined}
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: " Nom du Projet",
    },
    {
      dataField: "user",
      formatter: (cell) => (cell ? `${cell.first_name} ${cell.last_name}` : ""),
      text: "Nom du porteur",
    },
    {
      dataField: "objectif_levee",
      formatter: (cell) => formatAmout(cell),
      text: "Montant demandé",
    },
    {
      dataField: "progression",
      text: "Progression",
      formatter: (cell: any, row: any) => progressionFormat(cell, row),
    },
    {
      dataField: "statut",
      text: "En ligne ?",
      formatter: (cell: any, row: any) => statutFormat(cell, row),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <ProjetsAdminSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={data.results}
          columns={columns}
          striped
          bordered={false}
          condensed={false}
          responsive
          wrapperClasses="table-responsive"
          rowStyle={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "21px",
            color: "rgba(0, 0, 0, 0.7)",
          }}
          headerClasses="header-class"
        />
      )}
      <Pagination page={page} onPageChange={setPage} total={data.count} />
    </>
  );
}

export default ProjetsAdminTable;

function SwitchProjet({ item }: { item: Projet }) {
  const [update] = useAddProjetMutation();
  const [val, setVal] = useState(item.statut === "online");
  useEffect(() => {
    setVal(item.statut === "online");
  }, [item]);
  return (
    <Switch
      checked={val}
      onChange={(value) => {
        setVal(value);
        update({ id: item.id, data: { statut: value ? "online" : "offline" } });
      }}
      handleDiameter={15}
      offHandleColor="#C4C4C4"
      onHandleColor="#C4C4C4"
      height={18}
      width={40}
      borderRadius={0}
      uncheckedIcon={<div className="toggle-style-off">NON</div>}
      checkedIcon={<div className="toggle-style-on">OUI</div>}
      className="react-switch"
      id="small-radius-switch"
    />
  );
}
