import { HttpCodePage } from "page-http-code";
import React from "react";
type Props = any;
type State = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <HttpCodePage
          // text="Une erreur de serveur est survenue"
          redirectLink="/"
          typePage={500}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
