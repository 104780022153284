import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import AchatApi from "../../utils/api/achat/achat.api";
import { UserApi } from "../../utils/api/user/user.api";
import { useModalActions } from "../../utils/utils";
import AlerteConfirmation from "./stepsComponents/AlerteConfirmation";
import ConfirmationFinal from "./stepsComponents/ConfirmationFinal";

export function ConfirmationAchatModal({ modalId }) {
  const dispatch = useAppDispatch();
  const ma = useModalActions(modalId);
  const [params, setSearchParams] = useSearchParams();
  const [{ status }] = useState({
    status: params.get("status"),
  });

  useEffect(() => {
    setSearchParams({});
  }, []);

  const [loaded, setLoaded] = React.useState(false);
  useEffect(() => {
    if (document.readyState === "complete") {
      setLoaded(true);
    } else {
      document.addEventListener("DOMContentLoaded", () => {
        setLoaded(true);
      });
    }
  }, []);

  useEffect(() => {
    if (loaded) {
      if (status === "success") {
        dispatch(
          AchatApi.util.invalidateTags([
            "achat_commercant",
            "achat_user",
            "achats_admin",
          ])
        );
        setTimeout(() => {
          ma.show();
        }, 2000);
      }
    }
  }, [status, loaded]);

  return (
    <div
      className="modal fade"
      id={modalId}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="staticBackdrop2Label"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content custom-modal-investissement">
          <div className="modal-header modal-header-investissement-confirm">
            <button
              type="button"
              className="btn-close"
              onClick={() => ma.hide()}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body modal-invessetissement-body">
            <div className="modal-investissement-description-content2">
              <div className="modal-investissement-description-confirm">
                Paiement effectué avec succès
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ConfirmationInvestModal({ modalId }) {
  const dispatch = useAppDispatch();
  const ma = useModalActions(modalId);
  const [params, setSearchParams] = useSearchParams();
  const [{ phone, point, status }] = useState({
    status: params.get("status"),
    point: params.get("point"),
    phone: params.get("phone"),
  });

  useEffect(() => {
    setSearchParams({});
  }, []);

  const [loaded, setLoaded] = React.useState(false);
  useEffect(() => {
    if (document.readyState === "complete") {
      setLoaded(true);
    } else {
      document.addEventListener("DOMContentLoaded", () => {
        setLoaded(true);
      });
    }
  }, []);

  useEffect(() => {
    if (loaded) {
      if (status === "success") {
        dispatch(UserApi.util.invalidateTags(["user"]));
        setTimeout(() => {
          ma.show();
        }, 2000);
      }
    }
  }, [status, loaded]);

  return (
    <div
      className="modal fade"
      id={modalId}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="staticBackdrop2Label"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content custom-modal-investissement">
          <div className="modal-header modal-header-investissement-confirm">
            <button
              type="button"
              className="btn-close"
              onClick={() => ma.hide()}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body modal-invessetissement-body">
            <ConfirmationFinal
              phone={phone}
              onHide={() => ma.hide()}
              point={point}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationInvestModal;

export function ConfirmInvestModal({
  modalId,
  onConfirm,
  loading,
  projectName,
  onHide,
  funds,
}) {
  return (
    <div
      className="modal fade"
      id={modalId}
      data-bs-backdrop="static"
      data-backdrop="false"
      data-bs-keyboard="false"
      aria-labelledby="staticBackdrop2Label"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content custom-modal-investissement">
          <div className="modal-header modal-header-investissement-confirm">
            <button
              type="button"
              className="btn-close"
              onClick={onHide}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body modal-invessetissement-body">
            <AlerteConfirmation
              {...{ loading, onConfirm, projectName, funds, onHide }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
