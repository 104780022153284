import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { User, UserFormData } from "../../../../utils/api/user/user.type";
import { useUserAdminEditMutation } from "../../../../utils/api/userAdmin/userAdmin.api";
import { COLORS } from "../../../../utils/constants";
import { useModalActions } from "../../../../utils/utils";
import BtnCloseModal from "../../../shared/BtnCloseModal";
import BtnSubmit from "../../../shared/BtnSubmit";

const ModifierAdministrateurModal = ({
  item,
  modalId,
}: {
  item: User;
  modalId: string;
}) => {
  const ma = useModalActions(modalId);

  const [sendData, { isSuccess, isLoading, data }] = useUserAdminEditMutation();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        first_name: yup.string().required("Le prénom est requis"),
        last_name: yup.string().required("Le nom est requis"),
        phone: yup.string().nullable(),
        adresse: yup.string().nullable(),
        civilite: yup.string().nullable(),
      }),
    []
  );
  const { register, setValue, formState, clearErrors, handleSubmit, reset } =
    useForm<UserFormData>({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        id: item.id,
        project: item.project,
        categoryproduct: item.categoryproduct,
        categoryprojet: item.categoryprojet,
        commande: item.commande,
        faq: item.faq,
        message: item.message,
        parametre: item.parametre,
        avis: item.avis,
        slug: item.slug,
        first_name: item.first_name,
        last_name: item.last_name,
        phone: item.phone,
        email: item.email,
      },
    });

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]: any) => {
          console.log(key);
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (data && isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Modification réussie!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      });
      ma.hide();
    }
  }, [data, isSuccess]);

  const onSubmit = async (data) => {
    await sendData(data);
  };

  return (
    <div className="modal fade" id={modalId}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content py-3 px-2 px-xl-4">
          <div className="flex-csb mb-4 align-items-center">
            <h1 className="admin-modal-title m-0">
              Modifier {item?.first_name} {item?.last_name}
            </h1>
            <BtnCloseModal />
          </div>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6 form-group mb-3">
                <label htmlFor="prenom" className="admin-form-label">
                  Prénom
                </label>
                <input
                  type="text"
                  id="prenom"
                  className="form-control admin-form-control"
                  placeholder="Alpha"
                  {...register("first_name")}
                />
                {formState?.errors.first_name && (
                  <div className="alert alert-danger sia-alert-danger closer">
                    {formState?.errors.first_name?.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label htmlFor="prenom" className="admin-form-label">
                  Nom
                </label>
                <input
                  type="text"
                  id="nom"
                  className="form-control admin-form-control"
                  placeholder="Diallo"
                  {...register("last_name")}
                />
                {formState?.errors.last_name && (
                  <div className="alert alert-danger sia-alert-danger closer">
                    {formState?.errors.last_name?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group mb-3">
                <label htmlFor="prentelom" className="admin-form-label">
                  Téléphone
                </label>
                <input
                  type="tel"
                  id="tel"
                  className="form-control admin-form-control"
                  placeholder="77 700 00 00"
                  {...register("phone")}
                />
              </div>
              <div className="col-md-6 form-group mb-3">
                <label htmlFor="email" className="admin-form-label">
                  Adresse mail
                </label>
                <input
                  type="email"
                  id="email"
                  className="form-control admin-form-control"
                  placeholder="mail@gmail.com"
                  {...register("email")}
                />
                {formState?.errors.email && (
                  <div className="alert alert-danger sia-alert-danger closer">
                    {formState?.errors.email?.message}
                  </div>
                )}
              </div>
              <Permissions register={register} />
            </div>
            <div className="btn-container flex-r mt-5">
              {isLoading && (
                <button disabled className="admin-submit-btn">
                  En cours&nbsp; <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
              {!isLoading && <BtnSubmit label="Modifier" />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModifierAdministrateurModal;

export function Permissions({ register }) {
  return (
    <div>
      {permissions.map((permission) => {
        return (
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id={permission}
              {...register(permission)}
            />
            <label className="form-check-label" htmlFor={permission}>
              {getPermissionTitle(permission)}
            </label>
          </div>
        );
      })}
    </div>
  );
}

export function getPermissionTitle(per: string) {
  return PERMISSIONS[per] || "";
}

export const PERMISSIONS = {
  dashboard: "Dashboard",
  project: "Projets",
  users: "Utilisateurs",
  categoryproduct: "Catégorie produit",
  products: "Produits",
  categoryprojet: "Catégorie projet",
  commande: "commandes",
  faq: "FAQ",
  message: "Messages de contact",
  parametre: "Paramètres du site",
  avis: "Avis",
};

const permissions: (keyof typeof PERMISSIONS)[] = [
  // "dashboard",
  "project",
  "users",
  "products",
  "categoryproduct",
  "categoryprojet",
  "commande",
  "faq",
  "message",
  "parametre",
  "avis",
];

export function isAccessGranted(
  user?: User,
  permission?: keyof typeof PERMISSIONS
) {
  if (!user) return false;
  if (user.is_superuser || !permission) return true;
  return !!user[permission];
}
