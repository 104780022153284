import moment from "moment";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../redux/store";
import { useResponseContactMutation } from "../../../../utils/api/contact/contact.api";
import { Contact } from "../../../../utils/api/contact/contact.type";
import { COLORS } from "../../../../utils/constants";
import { useModalActions } from "../../../../utils/utils";
import BtnCloseModal from "../../../shared/BtnCloseModal";

const RepondreMessageModal = ({
  message,
  modalId,
}: {
  message: Contact;
  modalId: string;
}) => {
  const ma = useModalActions(modalId);
  const userId = useAppSelector((s) => s.user.user?.id as number);
  const [addResponse, { isLoading, isSuccess }] = useResponseContactMutation();
  const [response, setResponse] = useState("");
  useEffect(() => {
    if (isSuccess) {
      setResponse("");
      Swal.fire({
        icon: "success",
        title: "Réponse envoyé avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      });
      ma.hide();
    }
  }, [isSuccess]);
  return (
    <div className="modal fade" id={modalId}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content py-3">
          <div className="flex-csb mb-4 px-3">
            <h1 className="admin-modal-title m-0">
              Réponse à ({message?.fullname})
            </h1>
            <BtnCloseModal />
          </div>
          <div className="message-content px-3">
            <p>{message?.message}</p>
          </div>
          <hr className="maajjal-divider mt-0" />
          <div className="reponse-form-container px-3">
            <h3 className="admin-modal-sous-title">Réponses</h3>
            {message.responses.map((r) => (
              <div className="message-item px-3" key={r.id}>
                <div className="messages-text w-100">
                  <div className="message-infos flex-csb">
                    {/* <h3 className="author-message-name">{r.user}</h3> */}
                    <h6 className="hour-publish-message">
                      {moment(r.created_at).fromNow()}
                    </h6>
                  </div>
                  <div className="message-content">
                    <p>{r.response}</p>
                  </div>
                </div>
              </div>
            ))}
            <form>
              <div className="form-group">
                <textarea
                  name="reponse"
                  value={response}
                  onChange={(e) => setResponse(e.target.value)}
                  id="reponse"
                  placeholder="Ajouter une réponse"
                  className="form-control form-control-lg form-admin-textarea"
                />
              </div>
              <div className="btn-container flex-r mt-5">
                {isLoading && (
                  <button type="button" className="admin-submit-btn">
                    Chargement...
                  </button>
                )}
                {!isLoading && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (response.trim().length <= 0) {
                        return;
                      }
                      addResponse({
                        contact: message.id,
                        response: response,
                        user: userId,
                      });
                    }}
                    type="button"
                    className="admin-submit-btn"
                  >
                    Répondre
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepondreMessageModal;
