import moment from 'moment'
import { useStep } from 'react-hooks-helper'
import { useAppSelector } from '../../../../redux/store'
import { useProjetFromLocation } from '../../../../utils/api/launchCampagn/projet.api'
import {
  PAYMENT_TYPE,
  Projet,
} from '../../../../utils/api/launchCampagn/projet.type'
import { formatMontant, getImage } from '../../../../utils/utils'
import financerCampageImg2 from '../../../assets/appImage/financerCampageImg2.png'
import financerCampageImg3 from '../../../assets/appImage/financerCampageImg3.png'
import financerCampageImg4 from '../../../assets/appImage/financerCampageImg4.png'
import ConfirmationInvestModal from '../../../modales/ConfirmationInvestModal'
import InvestissementModal from '../../../modales/InvestissementModal'
import FrontFooter from '../../../shared/FrontFooter/FrontFooter'
import FrontHeader from '../../../shared/FrontHeader/FrontHeader'
import { SEO } from '../../../shared/SEO'
import { LikeButton } from '../../home/ProjectsList'
import { ShareModal } from '../../Modals/SharePoints/SharePointModal'
import './CampagneDetails.css'
import Commentaire from './stepComponents/Commentaire'
import Faq from './stepComponents/Faq'
import Presentation from './stepComponents/Presentation'
const steps: any = [
  { id: 'presentation', Component: Presentation },
  { id: 'commentaire', Component: Commentaire },
  { id: 'faq', Component: Faq },
]

function CampainsDetails() {
  const modalId = 'CampainsDetailsModal'

  const [item, isLoading] = useProjetFromLocation()

  const {
    navigation: { go },
    index,
  } = useStep({ initialStep: 0, steps })
  const { user } = useAppSelector((s) => s.user)
  const isAdmin = user?.user_type === 'admin'

  if (!item) return null

  return (
    <>
      <SEO title={item.name} />
      <div className="campagne-page-container">
        <div>
          <ConfirmationInvestModal modalId={modalId} />
          <FrontHeader />
        </div>
        <div className="campagne-page-content container-maajjal">
          <div className="row">
            <div className="col-md-7">
              <img
                src={getImage(item.photo)}
                className="card-img-detail-campagne"
                alt="img campagne"
              />
            </div>
            <div className="col-md-5">
              <div className="card custom-card-detail-campagne">
                <div className="card-body">
                  <h5 className="card-title card-details-campagne-title mb-4">
                    {item.name}
                  </h5>
                  <div className="card-campagne-progresse-container">
                    <div className="card-campagne-progresse-price-container mb-2">
                      <div className="card-detail-campagne-progresse-percentage">
                        ({`${Math.ceil(item.pourcentage)}%`})
                      </div>
                      <div className="card-detail-campagne-progresse-price">
                        {formatMontant(item?.funds)} (collectés)
                      </div>
                    </div>
                    <div
                      className="progress mb-3"
                      style={{
                        height: '10px',
                      }}
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${Math.ceil(item.pourcentage)}%`,
                          backgroundColor: '#0FBF56',
                        }}
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                    <div className="card-detail-campagne-progresse-details mb-4">
                      <p className="card-detail-campagne-progresse-details-para">
                        sur un objectif de {formatMontant(item?.objectif_levee)}
                      </p>
                    </div>
                  </div>
                  <div className="card-campagne-button-share-container mb-4">
                    <div className="card-detail-campagne-date-lancement">
                      {`Lancé ${moment(item?.date_debut).fromNow()}`}
                    </div>
                  </div>
                  {isAdmin || item.pourcentage >= 100 ? null : (
                    <div className="">
                      <small>Veuillez choisir un mode de paiement</small>
                      <div className="p-4">
                        <div className="row">
                          {/* <CardWarrapper>
                          <div className="fa-radio">
                            <div className="item">
                              <img
                                src={financerCampageImg1}
                                className="card-img-financer-campagne"
                                style={{ marginTop: 0 }}
                                alt="img campagne"
                              />
                            </div>
                          </div>
                        </CardWarrapper> */}

                          <CardWarrapper type="wave-senegal" item={item}>
                            <div className="fa-radio">
                              <div className="item">
                                <img
                                  src={financerCampageImg2}
                                  className="card-img-financer-campagne"
                                  style={{ marginTop: 0 }}
                                  alt="img campagne"
                                />
                              </div>
                            </div>
                          </CardWarrapper>
                          <CardWarrapper
                            type="orange-money-senegal"
                            item={item}
                          >
                            <div className="fa-radio">
                              <div className="item">
                                <img
                                  src={financerCampageImg3}
                                  className="card-img-financer-campagne"
                                  style={{ marginTop: 0 }}
                                  alt="img campagne"
                                />
                              </div>
                            </div>
                          </CardWarrapper>
                          <CardWarrapper type="orabank" item={item}>
                            <div className="fa-radio">
                              <div className="item">
                                <div style={{ textAlign: 'center' }}>
                                  <img
                                    src={financerCampageImg4}
                                    style={{ marginTop: 0 }}
                                    className="card-img-financer-campagne text-center"
                                    alt="img campagne"
                                  />
                                  <div className="card-container-financer-campagne-cb-text">
                                    carte bancaire
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardWarrapper>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr />
          <section className="presentation-projet">
            <div className="row">
              <div className="col-md-7">
                <div className="campagne-stepper-buttons-container mb-4">
                  <button
                    onClick={() => go?.(0)}
                    className={`stepper-mission ${
                      index === 0 && 'stepper-active-mission'
                    }`}
                  >
                    Presentation
                  </button>
                  <button
                    onClick={() => go?.(1)}
                    className={`stepper-mission ${
                      index === 1 && 'stepper-active-mission'
                    }`}
                  >
                    Commentaire
                  </button>
                  <button
                    onClick={() => go?.(2)}
                    className={`stepper-mission ${
                      index === 2 && 'stepper-active-mission'
                    }`}
                  >
                    Faq
                  </button>
                </div>
                {index === 0 && <Presentation item={item} />}
                {index === 1 && <Commentaire projet={item} />}
                {index === 2 && <Faq projet={item} />}
              </div>
              <div className="col-md-5">
                <p className="campagne-detail-projet-para-aside">
                  Partager et aimer pour l’avancement du projet{' '}
                </p>
                <div className="card-campagne-button-share-like">
                  <LikeButton
                    item={item}
                    modalShareId={'ShareModal' + item.id}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <FrontFooter />
        <ShareModal item={item} modalId={'ShareModal' + item.id} />
      </div>
    </>
  )
}

export default CampainsDetails

function CardWarrapper({
  type,
  item,
  children,
}: {
  type: PAYMENT_TYPE
  item: Projet
  children?: any
}) {
  const modalId = `${type}-${item.id}`
  return (
    <>
      <InvestissementModal type={type} modalId={modalId} projet={item} />
      <div
        data-bs-target={`#${modalId}`}
        data-bs-toggle="modal"
        style={{ cursor: 'pointer' }}
        className="col-md-3 col-6 mb-2"
      >
        {children}
      </div>
    </>
  )
}
