import ReactPlayer from "react-player";
import { formatMontant } from "../../../../../utils/utils";
import projetDescriptionVideo from "../../../../assets/appImage/projetDescriptionVideo.png";
import Play from "../../../../assets/icons/Play.png";

function Presentation({ item }) {
  console.log("item", item);
  return (
    <div className="campagne-detail-projet-description-container">
      <div className="campagne-detail-projet-description-title mb-3">
        Description
      </div>
      <p
        className="campagne-detail-projet-description-para mb-2"
        dangerouslySetInnerHTML={{ __html: item?.description }}
      />
      <div className="campagne-detail-projet-description-porteur-obj">
        <span className="campagne-detail-projet-description-porteur">
          Porteur de projet :
        </span>{" "}
        {item?.user?.first_name + " " + item?.user?.last_name}
      </div>
      <div className="campagne-detail-projet-description-porteur-obj">
        <span className="campagne-detail-projet-description-objectif">
          Objectif:
        </span>{" "}
        {formatMontant(item?.objectif_levee)} FCFA{" "}
      </div>
        {item.video_link != "" && (
      <div className="campagne-detail-projet-description-video ">
          <ReactPlayer
            url={`${item?.video_link}`}
            width="100%"
            height="400px"
            playing
            playIcon={<img src={Play} alt="icon play" />}
            light={projetDescriptionVideo}
            controls
            className="maajjal-video-player test"
          />
      </div>
        )}
    </div>
  );
}

export default Presentation;
