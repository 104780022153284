// DEV
// export const ApiUrl = 'https://recette-api-maajjal.withvolkeno.com'

export const COLORS = {
  blue: '#021849',
  green: '#0FBF56',
  red: '#DB0909',
}

// DEMO
export const ApiUrl = 'https://demo-api-maajjal.withvolkeno.com'

export const FACEBOOK_APP_ID = '8044653658886060'
export const GOOGLE_CLIENT_ID =
  '949836911625-hdamk9uqvendrdsbq8i2nnsmse2icks8.apps.googleusercontent.com'

export const categories = [
  { label: 'Agricole', value: 'agricole', id: 0 },
  { label: 'Commerce', value: 'commerce', id: 1 },
  { label: 'Transport', value: 'transport', id: 2 },
  { label: 'Immobilier', value: 'immobilier', id: 3 },
  { label: 'Services', value: 'services', id: 4 },
  { label: 'Sociale', value: 'sociale', id: 5 },
  { label: 'Autres', value: 'autres', id: 6 },
]
export const etatProjet = [
  { label: 'Pas encore lancé', value: 'offline' },
  { label: 'En ligne', value: 'online' },
  { label: 'Terminé', value: 'termine' },
]

export const ORDER_STATUT_SELECT = [
  { value: 'en_attente', label: 'Non validée' },
  { value: 'en_cours', label: 'En cours' },
  { value: 'annuler', label: 'Annulée' },
  { value: 'livrer', label: 'Livrée' },
  { value: 'valide', label: 'Validée' },
  { value: 'payer', label: 'Payée' },
]
export const ORDER_STATUT = {
  en_attente: {
    label: ORDER_STATUT_SELECT[0].label,
    color: '#c0392b',
  },
  en_cours: {
    label: ORDER_STATUT_SELECT[1].label,
    color: '#16a085',
  },
  annuler: {
    label: ORDER_STATUT_SELECT[2].label,
    color: '#c0392b',
  },
  livrer: {
    label: ORDER_STATUT_SELECT[3].label,
    color: '#16a085',
  },
  valide: {
    label: ORDER_STATUT_SELECT[4].label,
    color: '#16a085',
  },
  payer: {
    label: ORDER_STATUT_SELECT[5].label,
    color: '#16a085',
  },
}
