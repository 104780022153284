import moment from "moment";
import React from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { useAppSelector } from "../../../../../redux/store";
import { useCommentsByprojetQuery } from "../../../../../utils/api/commentaire/commentaire.api";
import { EmptyMessage } from "../../../../../utils/emptyMessage";
import { getAvatar } from "../../../../../utils/utils";
import Pagination from "../../../../shared/Pagination";
import ModifCommentaireModal from "../../../Modals/Commentaire/ModifCommentaireModal";
import UseAddCommentForm from "./UseAddCommentForm";

function Commentaire({ projet, commentEnable = true }) {
  const { user } = useAppSelector((s) => s.user);
  const isConnected = !!user?.id;
  const isAdmin = user?.user_type === "admin";
  const [page, setPage] = React.useState(1);
  const { data = { results: [] } } = useCommentsByprojetQuery({
    page,
    slug: projet.slug,
  });
  const commentaires = data.results;

  const { register, onSubmit, errors, isLoading } = UseAddCommentForm();

  return (
    <div className="campagne-detail-projet-description-container">
      {commentaires.map((comment) => {
        return (
          <div className="card card-body campagne-detail-projet-card-commentaire mb-3 px-0 ">
            <div className="campagne-detail-projet-card-commentaire-img  px-3">
              <div className="p1">
                <img
                  src={getAvatar(comment.user?.avatar)}
                  className="card-img-detail-campagne-commentaire"
                  alt="img campagne"
                />
              </div>
            </div>
            <div className="campagne-detail-projet-card-commentaire-text-container  px-2">
              <div className="campagne-detail-projet-card-commentaire-author mb-3 mt-1 mt-mb-0">
                {comment.user?.first_name} {comment.user?.last_name}
                <span
                  className="campagne-detail-projet-card-commentaire-date"
                  style={{ marginLeft: 10 }}
                >
                  {`${moment(comment.created_at).fromNow()}`}
                </span>
              </div>
              <p className="campagne-detail-projet-card-commentaire-para">
                {comment.commentaire}
              </p>
              {/* <div className="campagne-detail-projet-card-commentaire-action-btn-container">
                <button className="campagne-detail-projet-card-commentaire-action-btn-edit"
                data-bs-toggle="modal"
                data-bs-target="#modifierCommentaireModal"
                >
                  <AiOutlineEdit className="edit-btn" />
                </button>
                <button className="campagne-detail-projet-card-commentaire-action-btn-delete">
                  <FiTrash2 className={`supprimer-btn`} />
                </button>
              </div> */}
            </div>
            <ModifCommentaireModal comment={comment} />
          </div>
        );
      })}
      <Pagination onPageChange={setPage} page={page} total={data.count} />
      {commentaires.length === 0 &&
        EmptyMessage("Aucun commentaire disponible")}
      {isConnected && commentEnable && !isAdmin && (
        <div className="campagne-detail-projet-formulaire-commentaire ">
          <form className="row" onSubmit={onSubmit}>
            <div className="col-lg-8">
              <input
                type="text"
                className="form-control mb-3 mb-lg-0 campagne-detail-projet-formulaire-input"
                id="inputPassword2"
                placeholder="Laisser un commentaire"
                {...register("commentaire")}
              />
              {errors.commentaire && (
                <div className="alert alert-danger sia-alert-danger closer mt-2">
                  {errors.commentaire?.message}
                </div>
              )}
            </div>
            <div className="col-lg-4">
              {isLoading && (
                <button
                  disabled
                  className="btn mb-3 campagne-detail-projet-formulaire-buton"
                >
                  En cours&nbsp; <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
              {!isLoading && (
                <button
                  type="submit"
                  className="btn mb-3 campagne-detail-projet-formulaire-buton"
                >
                  Envoyer
                </button>
              )}
            </div>
          </form>
          {/* <ModifCommentaireModal /> */}
        </div>
      )}
    </div>
  );
}

export default Commentaire;
