import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useEffect } from "react";
import Swal from "sweetalert2";

import CategorieApi from "../../../../../utils/api/categorie/categorie.api";
import {
  Categorie,
  CategorieFormData,
} from "../../../../../utils/api/categorie/categorie.type";
import { COLORS } from "../../../../../utils/constants";
import { cleannerError } from "../../../../../utils/utils";

function useTeamForm(onHide: () => any, item?: Categorie) {
  const validationSchema = yup.object().shape({
    name: yup.string().required("Le nom est requis"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm<CategorieFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [addOrUpdate, { isLoading, isSuccess, error, isError }] =
    CategorieApi.useAddCategoryMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: item
          ? "Catégorie modifiée avec succès!"
          : "Catégorie ajoutée avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!item) {
          reset();
        }
        onHide();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur a survenue lors de ${
              item ? "la modification" : "l'ajout"
            }`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: CategorieFormData) => {
    addOrUpdate({ id: item?.id, data: data });
  };

  useEffect(() => {
    if (item) {
      const fields: (keyof CategorieFormData)[] = ["name", "description"];
      for (let field of fields) {
        register(field);
        setValue(field, item[field]);
      }
    }
  }, [item]);
  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
  };
}

export default useTeamForm;
