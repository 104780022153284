import { NavLink } from "react-router-dom";
import { Produit } from "../../../../../utils/api/produit/produit.type";
import { formatMontant, getImage } from "../../../../../utils/utils";
import { getRoutePath } from "../../../../routes/routes";
import BtnAjouterAuPanier from "./BtnAjouterAuPanier";
import NotificationModal from "./NotificationModal";

const ProductMarketCard = ({ produit }: { produit: Produit }) => {
  const {
    id,
    avatar,
    discount,
    point,
    name,
    description,
    prix,
    slug,
    price_discount,
  } = produit;

  return (
    <div className="col">
      <div className="card market-place-card pt-2 px-1 position-relative">
        <NavLink
          state={produit}
          to={getRoutePath("ProductMarketDetail", { slug: slug })}
          className="market-place-card-link"
        >
          <div className="flex-cc">
            <img
              src={getImage(avatar)}
              className="market-img custom-card-img-top"
              alt="produit"
            />
          </div>
          {!!discount && (
            <div className="texte-reduction-container">
              <span className="texte-reduction-top fw-600">
                {`-${discount}%`}
                <strong
                  style={{
                    fontWeight: 700,
                  }}
                >
                  ({`${point || 0} pts`})
                </strong>
              </span>
            </div>
          )}
        </NavLink>

        <div className="card-body">
          <div className="flex-c card-market-title">
            <h5 className="market-title">{name}</h5>
          </div>
          <div className="flex-sb ">
            <div className="flex-c gap-1 ">
              {!!discount && (
                <>
                  <h6 className="normal-price">{formatMontant(prix)}</h6>
                  <h6 className="price">{formatMontant(price_discount)}</h6>
                </>
              )}
              {!discount && <h6 className="price">{formatMontant(prix)}</h6>}
            </div>
          </div>

          <BtnAjouterAuPanier produit={produit} />
        </div>
        <NotificationModal />
      </div>
    </div>
  );
};

export default ProductMarketCard;
