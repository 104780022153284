import moment from "moment";
import "moment/locale/fr";
import { HttpCodePage } from "page-http-code";
import "page-http-code/dist/index.css";
import { useEffect } from "react";
import { ClearBrowserCacheBoundary } from "react-clear-browser-cache";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import {
  RenderAdminRoutes,
  RenderAuthRoutes,
  RenderCommercantRoutes,
  RenderCommonRoutes,
  RenderUserRoutes,
} from "./components/routes/renderRoutes";
import LoaderSuspense from "./components/shared/Loader";
import ErrorBoundary from "./components/utils/ErrorBoundary";
import { onSetUser } from "./redux/slices/user.slice";
import { useAppDispatch, useAppSelector } from "./redux/store";
import { useLikes } from "./utils/api/like/like.api";
import NotificationApi from "./utils/api/notification/notification.api";
import { useMeQuery } from "./utils/api/user/user.api";
moment.locale("fr");

function App() {
  const user = useAppSelector((s) => s.user.user);

  return (
    <div className="App">
      <ErrorBoundary>
        <ClearBrowserCacheBoundary
          auto
          fallback={<LoaderSuspense />}
          duration={60000}
        >
          <Router>
            <ScrollToTop />
            {!!user?.id && <FetchUserInfos />}
            <Routes>
              {RenderAuthRoutes()}
              {RenderUserRoutes()}
              {RenderAdminRoutes()}
              {RenderCommonRoutes()}
              {RenderCommercantRoutes()}
              <Route
                path={"/not-found"}
                element={
                  //@ts-ignore
                  <HttpCodePage
                    text="Page non trouvée"
                    typePage={404}
                    redirectLink="/"
                  />
                }
              />
              <Route path={"*"} element={<Navigate to={"/not-found"} />} />
            </Routes>
          </Router>
        </ClearBrowserCacheBoundary>
      </ErrorBoundary>
    </div>
  );
}

function FetchUserInfos() {
  useLikes();
  NotificationApi.useGetQuery();
  const dispatch = useAppDispatch();
  const { data } = useMeQuery();
  useEffect(() => {
    if (data) {
      dispatch(onSetUser(data));
    }
  }, [data]);
  return null;
}

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.state]);

  return null;
}

export default App;
