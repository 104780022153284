import React from "react";
import Logo from "../../../assets/appImage/LogoMajjal.png";

const AuthPanel: React.FC = () => {
	return (
		<div className="col-md-4 auth-panel bg-theme">
			<div className="logo-container">
				<img
					src={Logo}
					alt="app-logo"
					className="logo img-fluid"
				/>
			</div>
		</div>
	);
};

export default AuthPanel;
